import React, { useContext, useEffect } from 'react';
import EthereumContext from '../../context/ethereum';

const ExploreOne = () => {
    const initData = {
        pre_heading: "Exclusive Assets",
        heading: "Explore",
        btn_1: "View All",
        btn_2: "Load More"
    }

    const eth = useContext(EthereumContext);

    useEffect(() => {
        if (eth && eth.orderData.length === 0) {
            eth.getOrders();
        }
    }, [eth.orderData]);

    return(
        <EthereumContext.Consumer>
            {context => (
                <section className="explore-area load-more p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Intro */}
                                <div className="intro d-flex justify-content-between align-items-end m-0">
                                    <div className="intro-content">
                                        <span>{initData.pre_heading}</span>
                                        <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                    </div>
                                    <div className="intro-btn">
                                        <a className="btn content-btn" href="/explore-3">{initData.btn_1}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row items">
                            {context.orderData.map((item, idx) => {
                                return (
                                    <div key={`exo_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                        <div className="card">
                                            <div className="image-over">
                                                <a href={item.asset.openseaLink ? item.asset.openseaLink : '#'}>
                                                    <img className="card-img-top" src={item.asset.imageUrl} alt="" />
                                                </a>
                                            </div>
                                            {/* Card Caption */}
                                            <div className="card-caption col-12 p-0">
                                                {/* Card Body */}
                                                <div className="card-body">
                                                    <a href={item.asset.openseaLink ? item.asset.openseaLink : '#'}>
                                                        <h5 className="mb-0">{item.asset.name}</h5>
                                                    </a>
                                                    <div className="seller my-3">
                                                        <span>Owned By</span>
                                                        <h6 className="ml-2 mb-0">{item.asset.owner.address}</h6>
                                                    </div>
                                                    <div className="card-bottom my-3">
                                                        <span>Price</span>
                                                        <h6 className="ml-2 mb-0">{context.readablePrice(item.currentPrice)}</h6>
                                                    </div>
                                                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={() => context.buyToken(item)}><i className="icon-handbag mr-2" />Buy Token</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <button id="load-btn" className="btn btn-bordered-white mt-5">{initData.btn_2}</button>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </EthereumContext.Consumer>
    );
}

export default ExploreOne;