import React from 'react';
import EthereumContext from '../../context/ethereum';

const Header = () => (
    <EthereumContext.Consumer>
        {context => (
            <header id="header">
                {/* Navbar */}
                <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                    <div className="container header">
                        {/* Navbar Brand*/}
                        <a className="navbar-brand" href="/">
                            <img className="navbar-brand-sticky" src="img/logo.png" alt="sticky brand-logo" />
                        </a>
                    </div>
                </nav>
            </header>
        )}
    </EthereumContext.Consumer>
);

export default Header;