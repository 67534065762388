import React, { Component } from 'react';
import EthereumContext from './ethereum';
import * as Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { OrderSide } from 'opensea-js/lib/types';
import { OpenSeaPort, Network } from 'opensea-js';
import Portis from '@portis/web3';
import jQuery from 'jquery';
import Swal from 'sweetalert2';

class EthereumProvider extends Component {
    state = { 
      seaport: () => {},
      web3: () => {},
      accountAddress: "",
      ethBalance: 0.0,
      apiUrl: '',
      txUrl: '',
      orderData: [],
      ownTokens: []
    };

    ethEnabled = async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        return true;
      }
      return false;
    }

    defineNetwork = async () => {
      let web3 = this.state.web3;
      /* Network Id
        1: Mainnet
        3: Ropsten
        4: Rinkeby
      */
      let networkId = await web3.eth.net.getId();
      let apiUrl, txUrl;
      switch (networkId) {
        case 1:
          apiUrl = 'https://api.opensea.io';
          txUrl = 'https://etherscan.io/tx/';
          break;
        case 4:
          apiUrl = 'https://rinkeby-api.opensea.io';
          txUrl = 'https://rinkeby.etherscan.io/tx/';
          break;
        default:
          break;
      }
      
      this.setState({
        ...this.state,
        apiUrl: apiUrl,
        txUrl: txUrl
      });
    }

    initialize = async () => 
    {
      let web3;
      if (await this.ethEnabled()) {
          web3 = window.web3;            
      } else {
          const portis = new Portis('3d06ff67-9b02-4775-bc49-cbad3cac5264', 'rinkeby');
          web3 = new Web3(portis.provider);
      }

      const ethereum = web3.currentProvider;
      const accounts = await ethereum.enable();
      const account = accounts[0];

      const seaport = new OpenSeaPort(web3.currentProvider, {
          networkName: Network.Rinkeby
      });

      this.setState({
          ...this.state,
          web3: web3,
          seaport: seaport,
          accountAddress: account
      });

      await this.defineNetwork();
      await this.getBalance();
    }

    getBalance = async () => {
      let web3 = this.state.web3;
      let account = this.state.accountAddress;
      let self = this;

      web3.eth.getBalance(account, function(err, result) {
        if (err) {
          console.log(err)
        } else {
          self.setState({
            ...self.state,
            ethBalance: web3.utils.fromWei(result, "ether")
          });
        }
      });
    }

    getOrders = async () => {
      await this.initialize();

      let seaport = this.state.seaport;

      // eslint-disable-next-line no-unused-vars
      const { orders, count } = await seaport.api.getOrders({
        side: OrderSide.Sell,
        limit: 12
      });

      var ordersWithImages = orders.filter(function(order) {
          return order.asset ? order.asset.imageUrl : false;
      });

      ordersWithImages.forEach((order) => {
        this.setState(prevState => ({
          orderData: [...prevState.orderData, order]
        }))
      });

      (function ($) {
        $(".load-more .item").slice(0, 4).show();
      }(jQuery));
    }

    getPortfolio = async () => {
      await this.initialize();

      try {
        const response = await fetch(this.state.apiUrl + '/api/v1/assets?owner=' + this.state.accountAddress + '&limit=50&order_direction=desc');
        if (response.status === 200) {
            console.log("Machine successfully found.");
            const result = await response.json();
            this.setState({
              ...this.state,
              ownTokens: result.assets
            });
            
            (function ($) {    
              $(".load-more .item").slice(0, 4).show();
            }(jQuery));
        } else {
            console.log("not a 200");
        }
      } catch (err) {
        console.log(err);
      }
    }

    buyToken = async (order) => {
        let seaport = this.state.seaport;
        const account = this.state.accountAddress;
        // eslint-disable-next-line no-unused-vars
        const transactionHash = await seaport.fulfillOrder({ order, accountAddress: account});
    }

    sellToken = async (token) => {
      const inputValue = 0.001
      const inputStep = 0.001

      Swal.fire({
        title: 'Set a price in Gwei',
        input: 'number',
        customClass: {
          input: 'input-token',
          popup: 'card'
        },
        inputValue,
        inputAttributes: {
          min: 0,
          max: 1000,
          step: inputStep
        }
      })
      .then(async (result) => {
        if (result.value) {
          // 30 Days of expiration
          const expirationTime = Math.round(Date.now() / 1000 + 60 * 60 * 24 * 30)
          let seaport = this.state.seaport;
          let tokenId = token.token_id;
          let tokenAddress = token.asset_contract.address;
          let accountAddress = this.state.accountAddress;
          // eslint-disable-next-line no-unused-vars
          const listing = await seaport.createSellOrder({
            asset: {
              tokenId,
              tokenAddress,
              schemaName: token.asset_contract.schema_name
            },
            accountAddress,
            startAmount: result.value,
            expirationTime
          })
          .then((result) => {
            if (result) {
              Swal.fire({
                title: 'Success!',
                customClass: {
                  input: 'input-token',
                  popup: 'card'
                },
                html: 'Listing was create succesfully!',
                icon: 'success'}
              ); 
            } else {
              Swal.fire({
                title: 'Something failed!',
                customClass: {
                  input: 'input-token',
                  popup: 'card'
                },
                icon: 'error'}
              );
            }
          })
        }
      })      
    }

    sendToken = async (token) => {

    }

    readablePrice = price => {
        let num = new BigNumber(price);
        let denom = new BigNumber(10).pow(18);
        let ans = num.dividedBy(denom).toNumber();
        return ans;
    }

    render() {
        return (
            <EthereumContext.Provider
                value={{
                    accountAddress: this.state.accountAddress,
                    ethBalance: this.state.ethBalance,
                    orderData: this.state.orderData,
                    ownTokens: this.state.ownTokens,
                    getOrders: () => this.getOrders(),
                    getPortfolio: () => this.getPortfolio(),
                    readablePrice: price => this.readablePrice(price),
                    buyToken: order => this.buyToken(order),
                    sellToken: token => this.sellToken(token),
                    sendToken: token => this.sendToken(token)
                }}
            >
                {this.props.children}
            </EthereumContext.Provider>
        );
    }
}

export default EthereumProvider;